@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    height: 100svh;
    margin: 0;
    padding: 0;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.recharts-wrapper {
    width: 100% !important;
    height: 100% !important;
}

.recharts-legend-wrapper {
    width: 100% !important;
}

@media (min-width: 1024px) {
    .banner-side {
        display: block;
    }
}

.flip {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.video-id {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 7px;
}

.flipBack {
    transform: scaleX(1);
}

.mobile-nav {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    height: 64px;
    background: #1C5257;
    box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.1);
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 50;
    margin-bottom: 5%;
}

.nav-logo {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#orLine {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid rgb(179, 179, 179);
    line-height: 0.1em;
    margin: 10px 0 20px;
}

#orLine span {
    background: #f5f6fa;
    padding: 0 10px;
    color: gray;
}

.kycTitle {
    font-size: 36px;
    font-weight: 500;
}

.kycDescription {
    font-size: 14px;
}

.borderGreen {
    border: 1px solid #1b5257;
}

.camera-viewport {
    width: 226px;
    height: 226px;
    border-radius: 100%;
    position: relative;
    overflow: hidden;
    background-color: #34dc943b;
    border: 4px solid #34dc94;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

#dataExtracted {
    line-height: 42px;
}

.camera-viewport-red {
    width: 226px;
    height: 226px;
    border-radius: 100%;
    position: relative;
    overflow: hidden;
    background-color: #ff9089;
    border: 4px solid #d36c66;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.bigCheckIcon {
    width: 120px;
    height: 120px;
    margin: auto;
    border: 3px solid #34dc94;
    background-color: #e4fff0;
    border-radius: 50%;
}

.bigXIcon {
    width: 120px;
    height: 120px;
    margin: auto;
    border: 3px solid #a55450;
    background-color: #f8e2e1;
    border-radius: 50%;
}

.camera-guide {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.kycButton {
    background-color: #1C5257;
    color: white;
    width: 100%;
    padding: 0.75rem 1rem;
    border-radius: 24px;
    transition: background-color 0.2s;
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
    font-weight: 700;
}

.kycInput {
    border: 1px solid #1b5257;
    height: 45px;
}

.kycSelect {
    font-size: 14px;
    cursor: pointer;
}

.needHelp {
    font-size: 14px;
    cursor: pointer;
    width: fit-content;
}

.kycButton:hover {
    background-color: #164146;
    color: white;
}

.kycButtonLighter {
    background-color: #E8F1F1;
    color: #1C5257;
}

.kycButtonLighter:hover {
    background-color: #D8E5E5;
    color: #1C5257;
}

.graySquare {
    background-color: #e3e3e3;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;
    border-radius: 10px;
    border: 4px solid #c9c9c9;
    cursor: pointer;
    transition: 0.6s ease;
}

.frame {
    position: relative;
    border: 3px solid #78ffb2;
    height: 220px;
    border-radius: 10px;
    overflow: hidden;
}

.frameTextCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: black;
    font-size: 30px;
    color: white;
}

.graySquare.active {
    background-color: #e6fff0;
    border: 4px solid #1b5257;
    color: black;
    cursor: pointer;
}

.spinner {
    animation: spin 1.5s linear infinite;
    position: relative;
    width: 120px;
    height: 120px;
    margin: 100px auto 0;


    border: 12px solid #78ffb2;
    border-radius: 50%;


    &:after {
        content: "";
        width: 85%;
        height: 85%;
        background: -webkit-linear-gradient(45deg, rgba(245, 246, 250, 1) 0%, rgba(245, 246, 250, 1) 40%, rgba(245, 246, 250, 0.7) 60%, rgba(245, 246, 250, 0) 100%);
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-45%, -5%);
    }
}

.sidebar {
    transition: transform 0.3s ease-in-out;
    transform: translateX(0);
    /* Sidebar is visible */
    /* transition: width 0.3s ease-in-out; */
}

/* .collapsed {
    width: 0px;
} */

.sidebar.hiddenSidebar {
    /* transform: translateX(-100%); */
    display: none;
}

#openSidebar {
    background-color: #164146;
    width: 200px;
    height: 30px;
    transform: rotate(90deg);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    position: relative;
    left: -85px;
    cursor: pointer;
    border-radius: 20px 20px 0px 0px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 0 0% 3.9%;

        --card: 0 0% 100%;
        --card-foreground: 0 0% 3.9%;

        --popover: 0 0% 100%;
        --popover-foreground: 0 0% 3.9%;

        --primary: 0 0% 9%;
        --primary-foreground: 0 0% 98%;

        --secondary: 0 0% 96.1%;
        --secondary-foreground: 0 0% 9%;

        --muted: 0 0% 96.1%;
        --muted-foreground: 0 0% 45.1%;

        --accent: 0 0% 96.1%;
        --accent-foreground: 0 0% 9%;

        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 0 0% 98%;

        --border: 0 0% 89.8%;
        --input: 0 0% 89.8%;
        --ring: 0 0% 3.9%;

        --radius: 0.5rem;

        --chart-1: 12 76% 61%;

        --chart-2: 173 58% 39%;

        --chart-3: 197 37% 24%;

        --chart-4: 43 74% 66%;

        --chart-5: 27 87% 67%;

        --sidebar-background: 0 0% 98%;

        --sidebar-foreground: 240 5.3% 26.1%;

        --sidebar-primary: 240 5.9% 10%;

        --sidebar-primary-foreground: 0 0% 98%;

        --sidebar-accent: 240 4.8% 95.9%;

        --sidebar-accent-foreground: 240 5.9% 10%;

        --sidebar-border: 220 13% 91%;

        --sidebar-ring: 217.2 91.2% 59.8%;
        height: 100%;
    }

    .dark {
        --background: 0 0% 3.9%;
        --foreground: 0 0% 98%;

        --card: 0 0% 3.9%;
        --card-foreground: 0 0% 98%;

        --popover: 0 0% 3.9%;
        --popover-foreground: 0 0% 98%;

        --primary: 0 0% 98%;
        --primary-foreground: 0 0% 9%;

        --secondary: 0 0% 14.9%;
        --secondary-foreground: 0 0% 98%;

        --muted: 0 0% 14.9%;
        --muted-foreground: 0 0% 63.9%;

        --accent: 0 0% 14.9%;
        --accent-foreground: 0 0% 98%;

        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 0 0% 98%;

        --border: 0 0% 14.9%;
        --input: 0 0% 14.9%;
        --ring: 0 0% 83.1%;
        --chart-1: 220 70% 50%;
        --chart-2: 160 60% 45%;
        --chart-3: 30 80% 55%;
        --chart-4: 280 65% 60%;
        --chart-5: 340 75% 55%;
        --sidebar-background: 240 5.9% 10%;
        --sidebar-foreground: 240 4.8% 95.9%;
        --sidebar-primary: 224.3 76.3% 48%;
        --sidebar-primary-foreground: 0 0% 100%;
        --sidebar-accent: 240 3.7% 15.9%;
        --sidebar-accent-foreground: 240 4.8% 95.9%;
        --sidebar-border: 240 3.7% 15.9%;
        --sidebar-ring: 217.2 91.2% 59.8%;
    }
}

.veil {
    filter: blur(4px);
}

#blurDiv {
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    width: 100%;
    height: 100%;
    position: relative;
    top: -100%;
}

@layer base {
    * {
        @apply border-border;
    }

    body {
        @apply bg-background text-foreground;
    }
}


.fade-in {
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* create an animation for error validation under the input field, an animation that goes from up to down */
.error-validation {
    animation: errorValidation 0.3s ease-in-out;
}

@keyframes errorValidation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#sidebarLogo {
    max-width: 100px !important;
}

.sidebar-header {
    flex-direction: row;
    justify-content: space-around;
    width: 90%;
    align-items: center;
    padding: 0;
}

#main-dashboard {
    overflow-y: auto;
    height: 90svh;
    padding-left: 3rem;
    padding-right: 1rem;
    padding-top: 2rem;
}

#range-picker {
    margin: 1rem 0;
}

#range-picker p {
    text-align: center;
    margin-bottom: 0;
    font-size: 0.9rem;
    /* Adjust font size for smaller screens */
}

.greenBg {
    background-color: #1b5257 !important;
    color: white !important;
}

.lightGreenBg {
    background-color: #78FFB2;
    color: white;
}

.tableGreenBg {
    background-color: #e8fae3 !important;
}

.tableRedBg {
    background-color: #fcd5d5 !important;
}

.green {
    color: #1b5257;
}

.red {
    color: #dc3545;
}

.loading {
    pointer-events: none;
}

.email-clickable {
    text-decoration: underline;
}

.iframe-snippet {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid rgb(27 82 87);
}

.iframe-snippet:focus-visible {
    outline-color: #1b5257;
}

.red {
    color: red;
}

#viewKycData {
    font-family: "Inter";
}

.kycDataCard {
    background-color: white;
    min-height: 150px;
    padding: 30px;
    border-radius: 20px;
}

.viewKycDataTitle {
    color: #9898A0;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0.4rem;
}

.viewKycDataButton {
    color: black;
    padding: 10px;
    border-radius: 30px;
    font-size: 12px;
}

@keyframes pulseCircle {
    0% {
        box-shadow: 0 0 10px rgb(120, 255, 178, 0.9);
    }

    50% {
        box-shadow: 0 0 20px 10px rgb(120, 255, 178, 0.6);
    }

    /* Bigger glow */
    100% {
        box-shadow: 0 0 10px rgb(120, 255, 178, 0.9);
    }
}

.pulse-border {
    animation: pulseCircle 1.5s infinite ease-in-out;
    border-radius: 50%;
}

.docStyle {
    width: 135px;
    height: 85px;
}

@media (min-width: 992px) {
    .col-lg-2-half {
        width: 20%;
        flex: 0 0 auto;
    }

    .col-lg-9-half {
        width: 80%;
        flex: 0 0 auto;
    }
}

@media only screen and (max-width:700px) {
    #sidebarLogo {
        max-width: 70px !important;
    }

    .responsiveSideBar {
        width: 8rem !important;
    }

    .itemName {
        display: none;
    }

    .space-y-2 button,
    #settings {
        justify-content: center;
    }
}